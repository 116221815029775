<template>
  <div class="tw-flex tw-items-center tw-w-20 tw-h-9 tw-animate-pulse">
    <div
      class="tw-w-20 tw-h-4 tw-rounded-full"
      :class="altBg ? 'tw-bg-theme' : 'game-tile-background-color'"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    altBg: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
