import useInterrupted from '@composables/useInterrupted.js'
import useHandleError from '@errors/errorHandler.js'
import useRoute from '@composables/useRoute.js'
import { computed, ref } from 'vue'

export default function useAsync(
  cb = () => {},
  disableRouteErrorHandling = false
) {
  const { routePath } = useRoute()
  const { handleError } = useHandleError()
  // If the api request was made in a different route to it being completed
  // log the new path so it can be checked to stop code running using interrupt()
  const { startInterrupt, endInterrupt } = useInterrupted(routePath.value)
  const data = ref(null)
  const errorState = ref(null)
  const loading = ref(false)

  const run = async (...args) => {
    try {
      errorState.value = null
      loading.value = true
      startInterrupt(routePath.value)
      data.value = await cb(...args)
      endInterrupt(routePath.value)
    } catch (error) {
      handleError({
        error,
        errorState,
        disableRouteErrorHandling,
      })
    } finally {
      loading.value = false
    }
  }

  const error = computed(() => {
    return errorState.value
  })

  function setError(error) {
    errorState.value = error
  }

  return { data, error, loading, setError, run }
}
