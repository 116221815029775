export const AGREEMENT_TYPE_TOU = 1
export const AGREEMENT_TYPE_PRIVACY = 2
export const AGREEMENT_TYPE_DEVELOPER = 3
export const AGREEMENT_TYPE_API = 4
export const AGREEMENT_TYPE_PAYMENT = 5
export const AGREEMENT_TYPE_AUP = 6

export const STATUS_NOT_ACCEPTED = 0
export const STATUS_ACCEPTED = 1
export const STATUS_DELETED = 3
export const STATUS_BANNED = 4

export const PAGINATION_DEFAULT_PAGE = 1
export const PAGINATION_DEFAULT_TOTAL = 0
export const PAGINATION_DEFAULT_LIMIT = 30

export const RATINGS_LIKE = 1
export const RATINGS_DISLIKE = -1
export const RATINGS_UNDECIDED = 0
export const RATINGS_FEW = 5
export const RATINGS_MANY = 50

export const API_THIRD_PARTY_ACCESS = 1
export const API_DIRECT_DOWNLOAD = 2

export const GAME_COMMUNITY_ALLOW_NEGATIVE_RATINGS = 256

export const AGREEMENT_TYPE_OPTIONS = [
  { value: AGREEMENT_TYPE_TOU, text: 'Terms of Use', short: 'Terms' },
  { value: AGREEMENT_TYPE_PRIVACY, text: 'Privacy Policy', short: 'Privacy' },
  {
    value: AGREEMENT_TYPE_DEVELOPER,
    text: 'Developer Terms',
    short: 'Developer',
  },
  { value: AGREEMENT_TYPE_API, text: 'API Access Terms', short: 'API' },
  { value: AGREEMENT_TYPE_PAYMENT, text: 'Payment Terms', short: 'Payment' },
  { value: AGREEMENT_TYPE_AUP, text: 'Acceptable Use Policy', short: 'AUP' },
]

export const STATUS_OPTIONS = [
  { value: STATUS_NOT_ACCEPTED, text: 'Pending' },
  { value: STATUS_ACCEPTED, text: 'Accepted' },
  { value: STATUS_DELETED, text: 'Deleted' },
  { value: STATUS_BANNED, text: 'Banned' },
]

export const REPORT_TYPE_DMCA = 1

export const REPORT_TYPE_OPTIONS = [
  // API 0=generic, however it should not be a selectable option on report
  { value: 0, text: '' },
  { value: REPORT_TYPE_DMCA, text: 'report_dmca' },
  { value: 2, text: 'report_not_working' },
  { value: 3, text: 'report_rude_content' },
  { value: 4, text: 'report_illegal_content' },
  { value: 5, text: 'report_stolen_content' },
  { value: 6, text: 'report_false_information' },
  { value: 7, text: 'report_other' },
]

// These values are consistent with the API
export const RESOURCE_GAMES = 'games'
export const RESOURCE_MODS = 'mods'
export const RESOURCE_GUIDES = 'articles'
export const RESOURCE_USERS = 'users'

export const RESOURCE_OPTIONS = {
  [RESOURCE_GAMES]: 'Game',
  [RESOURCE_MODS]: 'Mod',
  [RESOURCE_GUIDES]: 'Guide',
  [RESOURCE_USERS]: 'User',
}

export const RATINGS_TEXT_OPTIONS = {
  UNRATED: 'unrated',
  OVERWHELMINGLY_POSITIVE: 'overwhelmingly_positive',
  VERY_POSITIVE: 'very_positive',
  POSITIVE: 'positive',
  MOSTLY_POSITIVE: 'mostly_positive',
  MIXED: 'mixed',
  MOSTLY_NEGATIVE: 'mostly_negative',
  NEGATIVE: 'negative',
  VERY_NEGATIVE: 'very_negative',
  OVERWHELMINGLY_NEGATIVE: 'overwhelmingly_negative',
}

export const PAGINATION_LIMIT_OPTIONS = [
  { value: 30, text: '30/Page' },
  { value: 40, text: '40/Page' },
  { value: 50, text: '50/Page' },
]
