import { createRouter, createWebHashHistory } from 'vue-router'
import { MOD_BROWSE_ROUTE } from '@config/routeNames.js'
import { SCROLL_TO_ID } from '@config/htmlIDs.js'
import { handleScroll } from '@helpers/utils.js'
import routes from '@router/allRoutes.js'
import { authStore } from '@stores'

const { isLoggedIn } = authStore()
const savedScrollPositions = new Map()

const router = new createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: (to, from, savedPos) => {
    const key = from.fullPath + '|' + to.fullPath
    // If used browser back button, scroll to saved postiion
    if (savedPos && savedScrollPositions.has(key)) {
      document.getElementById(SCROLL_TO_ID).scrollTop =
        savedScrollPositions.get(key)
      savedScrollPositions.delete(key)
    } else {
      handleScroll(to, from)
    }
  },
})

router.beforeEach((to) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isLoggedIn.value) {
      return { name: MOD_BROWSE_ROUTE }
    }
  }
})

router.afterEach((to, from) => {
  savedScrollPositions.set(
    to.fullPath + '|' + from.fullPath,
    document.getElementById(SCROLL_TO_ID).scrollTop
  )
})

export default router
