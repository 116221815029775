import { useEventListener } from '@vueuse/core'
import { ref, computed } from 'vue'

export default function () {
  const state = ref(false)

  const hovered = computed(() => state.value)

  function watchHover(target) {
    const mouseover = () => {
      state.value = true
    }

    const mouseleave = () => {
      state.value = false
    }
    useEventListener(target, 'mouseover', mouseover)
    useEventListener(target, 'mouseleave', mouseleave)
    return hovered
  }

  return {
    watchHover,
  }
}
