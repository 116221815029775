import { GAME_ID } from '@config/game.js'
import { computed, unref } from 'vue'
import { gameStore } from '@stores'

const UPPERCASE_UGC_NAMES = ['UGC', 'UGX']

export default function () {
  const { getPublicGame } = gameStore()

  function ugcName(game, singular, lower) {
    return computed(() => {
      let _game = unref(game)

      if (!_game) {
        _game = getPublicGame(GAME_ID).value
      }

      let _ugcName = _game?.ugc_name || 'Mods'
      if (singular) {
        _ugcName = _game?.ugc_name_single || _ugcName.replace(/s$/, '')
      }

      if (lower && !UPPERCASE_UGC_NAMES.includes(_ugcName)) {
        _ugcName = _ugcName.toLowerCase()
      }

      return _ugcName
    })
  }

  return {
    ugcName,
  }
}
