import { requestAll } from '@helpers/requestAll.js'
import { PUBLIC_API_KEY } from '@config/game.js'
import { PUBLIC_BASE_URL } from '@config'

/* API request to get files */
export function getAllFilesRequest(gameId, modId, query) {
  query.api_key = PUBLIC_API_KEY
  return requestAll(
    `${PUBLIC_BASE_URL}/games/${gameId}/mods/${modId}/files`,
    query
  )
}
