<template>
  <div
    v-if="inputLoading"
    class="tw-w-8 tw-h-8 tw-animate-pulse tw-rounded-full"
  />
  <div v-else class="tw-relative">
    <div
      v-if="avatarUrl && !onlyUseInitials"
      class="tw-overflow-hidden tw-rounded-full"
      :class="avatarSize"
    >
      <img
        :src="avatarUrl"
        class="tw-object-cover tw-object-center tw-button-transition tw-h-full tw-w-full"
        :alt="user && user.name"
      />
    </div>

    <span
      v-else
      class="tw-font-extrabold tw-rounded-full tw-flex tw-items-center tw-justify-center tw-button-transition game-primary-button-color game-link-color game-primary-text tw-pointer-events-none"
      :class="avatarSize"
    >
      <div
        class="tw-absolute tw-inset-0.5 tw-border tw-rounded-full game-primary-button-border-color"
      />
      <span :class="initialSize">{{ userInitials }}</span>
    </span>

    <div
      v-if="showStatus && onlineStatus"
      class="tw-absolute tw-rounded-full tw-border-theme-3"
      :class="[onlineIconStyles, onlineStatus ? 'tw-bg-success' : 'tw-bg-grey']"
    ></div>
  </div>
</template>

<script>
import { isOnlineUser } from '@helpers/utils.js'
import { toRefs, computed } from 'vue'
export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: 'md',
      validator: function (value) {
        return ['sm', 'md', 'lg', 'xl'].indexOf(value) !== -1
      },
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    onlyUseInitials: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { user, size } = toRefs(props)

    const userInitials = computed(() => {
      if (user.value?.username) {
        return user.value.username.substring(0, 1).toUpperCase()
      }
      return ''
    })
    const avatarUrl = computed(
      () =>
        user.value &&
        user.value.avatar.thumb_100x100?.search('placeholder/') === -1 &&
        user.value.avatar.thumb_100x100
    )
    const avatarSize = computed(() => {
      if (size.value === 'sm') {
        return 'tw-w-7 tw-h-7'
      } else if (size.value === 'lg') {
        return 'tw-w-10 tw-h-10'
      } else if (size.value === 'xl') {
        return 'tw-h-20 tw-w-20 sm:tw-h-28 sm:tw-w-28 '
      }
      return 'tw-w-9 tw-h-9'
    })
    const onlineIconStyles = computed(() => {
      if (size.value === 'xl') {
        return 'tw-h-6 tw-w-6 tw--top-2 tw--right-2 tw-border-2'
      }
      return 'tw-h-3 tw-w-3 tw--top-1 tw--right-1 tw-border-1.5'
    })
    const initialSize = computed(() =>
      size.value === 'xl' ? 'tw-text-h3' : 'tw-text-h6'
    )
    const onlineStatus = computed(() => isOnlineUser(user.value?.date_online))

    return {
      onlineIconStyles,
      userInitials,
      onlineStatus,
      initialSize,
      avatarSize,
      avatarUrl,
    }
  },
}
</script>
