import { isObj, isString } from '@helpers/utils.js'

class ErrorMessages {
  constructor(_errorTable) {
    this.errorMap = isObj(_errorTable)
      ? new Map(Object.entries(_errorTable))
      : new Map()
  }

  getErrorMessage(key) {
    if (!key) {
      return ''
    }

    if (isString(key) && key.startsWith('error.')) {
      const message = this.errorMap.get(key)
      return message || ''
    }

    return key
  }
}

export default new ErrorMessages()
