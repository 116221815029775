import { LS_LOCAL_MODS } from '@config/localstorage.js'
import useStorage from '@composables/useStorage.js'
import { isArray } from '@helpers/utils.js'
import { computed } from 'vue'

const localModState = useStorage(LS_LOCAL_MODS, null, false)

export default function () {
  const localMods = computed(() => {
    try {
      const json = JSON.parse(localModState.value)
      if (isArray(json)) return json
    } catch (error) {
      console.error(error)
    }
    return []
  })

  function clearLocalMods() {
    localModState.value = JSON.stringify([])
  }

  function setLocalMods(LocalMods) {
    localModState.value = JSON.stringify(LocalMods)
  }

  // use game and mod id
  function addLocalMod(modId, modData) {
    if (isArray(localMods.value)) {
      // == as int or string
      if (!localMods.value.some((m) => m?.id == modId)) {
        setLocalMods([...localMods.value, modData])
      }
    } else {
      setLocalMods([modData])
    }
  }

  // use game and mod id
  function removeLocalMod(modId) {
    // != as int or string
    const updated = localMods.value.filter((mod) => mod?.id != modId)

    setLocalMods(updated)
  }

  return {
    removeLocalMod,
    clearLocalMods,
    setLocalMods,
    addLocalMod,
    localMods,
  }
}
