import { isFunction, isArray } from '@helpers/utils.js'
import { PUBLIC_API_KEY } from '@config/game.js'
import { create } from './axiosClient.js'
import { authStore } from '@stores'
import { HttpError } from '@errors'
import qs from 'qs'

const { isLoggedIn, clearUser } = authStore()

export const TOKEN_EXPIRED = 11005

export function useApiKey(url) {
  if (!url.includes('api_key=')) {
    return (
      url +
      (url.includes('?') ? '&' : '?') +
      `${qs.stringify({ api_key: PUBLIC_API_KEY })}`
    )
  } else {
    return url
  }
}

export default function createClient(configs = []) {
  let client = {
    httpClient: create(),
  }

  const updateConfigs = isArray(configs) ? configs : Array.from([configs])

  updateConfigs.forEach((updateConfigFn) => {
    if (!isFunction(updateConfigFn)) {
      throw new TypeError('Argument must be a function')
    }
    updateConfigFn(client)
  })

  async function get(uri, config = {}) {
    const _uri = config.bustCache
      ? uri.includes('?')
        ? `${uri}&ts=${Date.now()}`
        : `${uri}?ts=${Date.now()}`
      : uri

    try {
      return await client.httpClient.get(_uri, config)
    } catch (err) {
      if (err.response) {
        await __handleUnauthorise(err)
        throw new HttpError(err.response)
      }
    }
  }

  async function post(uri, postData, config = {}) {
    try {
      return await client.httpClient.post(uri, postData, config)
    } catch (err) {
      if (err.response) {
        await __handleUnauthorise(err)
        throw new HttpError(err.response)
      }
    }
  }

  async function del(uri, postData = null, config) {
    try {
      return await client.httpClient.delete(uri, { ...config, data: postData })
    } catch (err) {
      if (err.response) {
        await __handleUnauthorise(err)
        throw new HttpError(err.response)
      }
    }
  }

  async function __handleUnauthorise(error) {
    if (!isLoggedIn.value) return

    if (parseInt(error.response.status) === 401) {
      if (error.response.data?.error?.error_ref === TOKEN_EXPIRED) {
        clearUser()
        window.location.reload()
      }
    }
  }

  function setClient(c) {
    client.httpClient = c
  }

  function getClient() {
    return client.httpClient
  }

  return {
    getClient,
    setClient,
    post,
    get,
    del,
  }
}
