import { watch, computed, ref } from 'vue'

export default function (instanceSlots) {
  if (!instanceSlots) {
    throw new TypeError()
  }
  const state = ref({})

  Object.keys(instanceSlots).forEach(
    (slotName) => (state.value[slotName] = true)
  )

  const slots = computed(() => state.value)

  function showSlot(slotName) {
    state.value[slotName] = true
  }

  function hideSlot(slotName) {
    state.value[slotName] = false
  }

  function watchSlot(slotName, conditionRef) {
    watch(
      conditionRef,
      (value) => {
        state.value[slotName] = !!value
      },
      { immediate: true }
    )

    return !!conditionRef.value
  }

  return { watchSlot, hideSlot, slots, showSlot }
}
