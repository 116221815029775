import { KEYS_MODS, KEYS_FEATURED } from '@config/storeKeys.js'
import modSerializer from '@serializers/modSerializer.js'
import { FEATURED_LIMIT } from '@config/game.js'
import store from '@stores/store.js'
import { computed } from 'vue'

const { addToStoreData, createStore, updateStore, generateId, getStore } =
  store()

export default function () {
  function getMods(gameNameId) {
    return getStore(generateId(KEYS_MODS, gameNameId))
  }

  const featuredMods = getStore(KEYS_FEATURED)

  function getModByNameId(gameNameId, modNameId) {
    if (!gameNameId || !modNameId) {
      throw new TypeError()
    }

    const mods = getStore(generateId(KEYS_MODS, gameNameId))

    return computed(() =>
      mods.value?.data
        ? mods.value.data.find((mod) => mod.name_id === modNameId)
        : null
    )
  }

  function getModById(gameNameId, modId) {
    const mods = getStore(generateId(KEYS_MODS, gameNameId))

    return computed(() =>
      mods.value?.data ? mods.value.data.find((mod) => mod.id == modId) : null
    )
  }

  function addMods(gameNameId, mods, query) {
    createStore(generateId(KEYS_MODS, gameNameId), {
      ...modSerializer.transform(mods),
      query,
    })
  }

  function addFeaturedMods(mods) {
    createStore(
      KEYS_FEATURED,
      modSerializer.transform(mods).data.slice(0, FEATURED_LIMIT)
    )
  }

  function removeMods(gameNameId) {
    updateStore(generateId(KEYS_MODS, gameNameId))
  }

  function addMod(gameNameId, mod) {
    const _mod = modSerializer.transform(mod)

    addToStoreData(generateId(KEYS_MODS, gameNameId), _mod)

    return _mod
  }

  return {
    addFeaturedMods,
    getModByNameId,
    featuredMods,
    getModById,
    removeMods,
    addMods,
    getMods,
    addMod,
  }
}
