<template>
  <div class="tw-animate-pulse">
    <div>
      <div
        v-if="!hideTitle"
        class="tw-w-20 tw-h-3 sm:tw-h-4 tw-rounded-full tw-mb-4 game-tile-background-color"
      ></div>
      <div class="tw-space-y-4">
        <div
          class="tw-w-3/4 tw-h-2 sm:tw-h-3 tw-rounded-full game-tile-background-color"
        ></div>
        <div
          class="tw-w-full tw-h-2 sm:tw-h-3 tw-rounded-full game-tile-background-color"
        ></div>
        <div
          class="tw-w-1/4 tw-h-2 sm:tw-h-3 tw-rounded-full game-tile-background-color"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
