import { REPLICATION_DELAY_MS, PUBLIC_BASE_URL, LOGOUT_ENDPOINT } from '@config'
import { subscriptionStore, ratingStore, authStore } from '@stores'
import * as sdkInterface from '@plugins/sdkInterface.js'
import { PUBLIC_API_KEY } from '@config/game.js'
import useToast from '@composables/useToast.js'
import { asyncSleep } from '@helpers/utils.js'
import i18n from '@plugins/localisation.js'
import qs from 'qs'
import {
  getMySubscriptionsRequest,
  getAllUserRatings,
  getUserRequest,
  createClient,
} from '@services'

const { clearUser, setToken, setUser, isLoggedIn } = authStore()
const { setMySubscriptions } = subscriptionStore()
const { setUserRatings } = ratingStore()
const { post } = createClient()

/* API request to update local storage data */
export async function updateLocalStorage() {
  const response = await Promise.allSettled([
    getUserRequest(),
    getMySubscriptionsRequest(),
    getAllUserRatings(),
  ])

  if (response.some((promise) => promise.reason?.code === 401)) {
    // unauthorised
    clearUser()
    const t = i18n.global.t
    useToast().addToast({
      title: t('login_fail'),
      isError: true,
      text: t('login_fail_message'),
    })
    throw new Error()
  }

  if (response[1].status === 'fulfilled')
    setMySubscriptions(response[1].value.data)
  if (response[2].status === 'fulfilled') setUserRatings(response[2].value.data)

  // set user required to HERE to kept login modal visible
  // until local storage data requests complete
  if (response[0].status === 'fulfilled') {
    setUser(response[0].value)
  }
}

/* API request to request code */
export async function requestCodeRequest({ email } = {}) {
  const query = { api_key: PUBLIC_API_KEY, email }
  const { data } = await post(
    `${PUBLIC_BASE_URL}/oauth/emailrequest`,
    qs.stringify(query)
  )
  return data
}

/* API request to login using code */
export async function loginRequest({ code } = {}) {
  const query = { api_key: PUBLIC_API_KEY, security_code: code }
  const {
    data: { access_token },
  } = await post(`${PUBLIC_BASE_URL}/oauth/emailexchange`, qs.stringify(query))

  await _boot({ access_token })
}

/* API request to logout */
export async function logoutRequest() {
  if (!isLoggedIn.value) return

  try {
    await post(LOGOUT_ENDPOINT)
  } catch (err) {
    console.error(err)
  } finally {
    clearUser()
  }
}

async function _boot({ access_token }) {
  // handle replication delay
  await asyncSleep(REPLICATION_DELAY_MS)

  try {
    clearUser()
    setToken(access_token)
    sdkInterface.setAuthToken(access_token)

    await updateLocalStorage()
  } catch (error) {
    console.error(error)
  }
}
