import gameSerializer from '@serializers/gameSerializer.js'
import { KEYS_GAMES } from '@config/storeKeys.js'
import { STATUS_ACCEPTED } from '@config/options'
import { clone } from '@helpers/utils.js'
import store from '@stores/store.js'
import { computed } from 'vue'

const { addToStoreData, getStore, createStore } = store()
const games = getStore(KEYS_GAMES)

export default function () {
  function getPublicGames() {
    return computed(() => {
      if (!games.value) return null

      const _games = clone(games.value)

      const items = _games.data.filter(
        (game) => game.status === STATUS_ACCEPTED
      )

      _games.data = items
      _games.result_count = items.length
      _games.result_total = items.length

      return _games
    })
  }

  function getPublicGame(id) {
    return computed(() =>
      games.value?.data ? games.value.data.find((game) => game.id == id) : null
    )
  }

  function addPublicGame(game) {
    const _game = gameSerializer.transform(game)
    addToStoreData(KEYS_GAMES, _game)
    return _game
  }

  function addPublicGames(data) {
    createStore(KEYS_GAMES, gameSerializer.transform(data))
  }

  return {
    addPublicGames,
    getPublicGames,
    addPublicGame,
    getPublicGame,
  }
}
