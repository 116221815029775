import useInterrupted from '@composables/useInterrupted.js'
import { FOUROHFOUR_ROUTE } from '@config/routeNames.js'
import { isObjEmpty, clone } from '@helpers/utils.js'
import { computed } from 'vue'
import router from '@router'

export default function () {
  const { interrupted } = useInterrupted()
  let route
  let $router

  route = router.currentRoute
  $router = router

  const routeParams = computed(() => route.value.params)
  const routeQuery = computed(() => route.value.query)
  const routePath = computed(() => route.value.path)
  const routeFullPath = computed(() => route.value.fullPath)
  const routeMeta = computed(() => route.value.meta)
  const routeError = computed(() => route.value.meta.error)
  const routeName = computed(() => route.value.name)
  const routeHash = computed(() => {
    const query = {}
    route.value.hash
      .replace('#', '')
      .split('&')
      .forEach((hash) => {
        const parts = hash.split('=')
        query[parts[0]] = parts[1]
      })

    return query
  })

  const hasParams = computed(() => !isObjEmpty(routeParams.value))

  function resolve(to) {
    try {
      return $router.resolve(to)
    } catch {
      console.error('could not resolve route')
    }
    return null
  }

  function routeHasParams(routeName) {
    return Object.keys(getRouteNameParams(routeName)).length !== 0
  }

  function getRouteNameParams(routeName) {
    if (routeName) {
      const route = $router.resolve({ name: routeName })
      const params = route && Object.values(route.params)
      const _routeParams = clone(routeParams.value)
      Object.entries(_routeParams).forEach(([key, value]) => {
        if (!params.includes(value)) {
          delete _routeParams[key]
        }
      })
      return _routeParams
    } else {
      return {}
    }
  }

  function getPathname() {
    const url = new URL(window.location.href)
    return url.pathname
  }

  function push(to) {
    $router.push(to)
  }

  function replace(to) {
    $router.replace(to)
  }

  function fourOhFour() {
    if (interrupted(routePath.value)) {
      return
    }
    push({
      name: FOUROHFOUR_ROUTE,
      // preserve current path and remove the first char to avoid the target URL starting with `//`
      params: { pathMatch: route.value.path.substring(1).split('/') },
    })
  }

  function getModId() {
    return computed(() => route.value?.params?.mod)
  }

  return {
    getRouteNameParams,
    routeHasParams,
    routeFullPath,
    routeParams,
    getPathname,
    fourOhFour,
    routeQuery,
    routeError,
    hasParams,
    routeHash,
    routeName,
    routeMeta,
    routePath,
    getModId,
    resolve,
    replace,
    route,
    push,
  }
}
