import {
  LOCALISATION_ROUTE,
  COLLECTION_ROUTE,
  MOD_BROWSE_ROUTE,
  FOUROHFOUR_ROUTE,
  MOD_VIEW_ROUTE,
} from '@config/routeNames.js'

export default [
  {
    path: '/',
    name: MOD_BROWSE_ROUTE,
    component: () =>
      import(/* webpackChunkName: "modio" */ '@views/ModBrowse.vue'),
    meta: { browse: true },
  },
  {
    path: '/m/:mod',
    name: MOD_VIEW_ROUTE,
    component: () =>
      import(/* webpackChunkName: "modio" */ '@views/ModView.vue'),
  },
  {
    path: '/collection',
    name: COLLECTION_ROUTE,
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "modio" */ '@views/Collection.vue'),
  },
  {
    path: '/localisation',
    name: LOCALISATION_ROUTE,
    component: () => import('@views/Localisation.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: FOUROHFOUR_ROUTE,
    component: () => import('@views/404.vue'),
  },
].filter((route) => route.name !== LOCALISATION_ROUTE || import.meta.env.DEV)
