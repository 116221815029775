import { generateSortQuery, clone, isString, isArray } from '@helpers/utils.js'
import { computed, ref } from 'vue'
import get from 'lodash/get'

const state = ref({})

export default function (id, param, sortOptions) {
  if (!id) {
    throw new Error('useSort requires id')
  }

  if (param && isString(param) && sortOptions) {
    const sortTemp = normalizeSort(param)
    const matchingSort = sortOptions.find((s) => s.key === sortTemp.sortBy)
    setSort({ ...matchingSort, ...sortTemp })
  }

  function setSort({ sortBy, order, reverse, alias, direction = 'asc' } = {}) {
    const _order = !order ? direction : order
    state.value[id] = {
      sortBy,
      order: _order,
      reverse,
      alias,
      direction,
    }
  }

  const sort = computed(() => {
    return state.value && state.value[id]
  })

  function clearSort() {
    delete state.value[id]
  }

  const getSortQuery = computed(() => {
    if (!state.value[id]) return null

    const sort = state.value[id].sortBy
      ? generateSortQuery(state.value[id].order, state.value[id].sortBy)
      : null

    return {
      _sort: sort,
    }
  })

  function inMemorySort({ data } = {}) {
    if (!sort.value || !data) {
      return data
    }

    if (isArray(data)) {
      return _sortItems(data)
    }

    if (data && data.data && !_requireFetch(data)) {
      return { ...data, data: _sortItems(data.data) }
    } else {
      return null
    }
  }

  function _sortable(value) {
    return value !== null && value !== undefined
  }

  function _sortItems(data) {
    let { order, sortBy, reverse, alias } = sort.value
    const resultsCopy = clone(data)

    return resultsCopy.sort((first, second) => {
      const search = alias ? alias : sortBy

      let firstItem = get(first, search)
      let secondItem = get(second, search)

      if (_sortable(firstItem) && !_sortable(secondItem)) return -1
      if (!_sortable(firstItem) && _sortable(secondItem)) return 1
      if (!_sortable(firstItem)) return 0

      if (reverse) {
        return order === 'asc'
          ? _sortByDesc(firstItem, secondItem)
          : _sortByAsc(firstItem, secondItem)
      }

      return order === 'asc'
        ? _sortByAsc(firstItem, secondItem)
        : _sortByDesc(firstItem, secondItem)
    })
  }

  function _requireFetch({ result_count, result_total }) {
    return result_count !== result_total ? true : false
  }

  function _sortByAsc(first, second) {
    return isString(first) && isString(second)
      ? first.toString().localeCompare(second.toString())
      : first - second
  }

  function _sortByDesc(first, second) {
    return isString(first) && isString(second)
      ? second.toString().localeCompare(first.toString())
      : second - first
  }

  function getDefaultSort() {
    return sortOptions && sortOptions.find((opt) => opt.default)
  }

  return {
    getDefaultSort,
    getSortQuery,
    inMemorySort,
    clearSort,
    setSort,
    sort,
  }
}

export function normalizeSort(_sort) {
  if (!isString(_sort)) {
    throw new TypeError('normalizeSort requires argument to be of type string')
  }

  return {
    sortBy: _sort ? _sort.replace('-', '') : '',
    order: _sort ? (_sort.includes('-') ? 'desc' : 'asc') : '',
  }
}
