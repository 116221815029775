import { useStorage as vueuseStorage, useLocalStorage } from '@vueuse/core'
import { LS_COOKIE } from '@config/localstorage.js'
import { areBitsSet } from '@helpers/utils.js'

export const cookieState = useLocalStorage(LS_COOKIE, null)
export const ALLOW_COOKIES = 1

export default function (key, defaultValue, isPreference = false) {
  const cookieState = vueuseStorage(LS_COOKIE, null, localStorage)
  const cookieValue = parseInt(cookieState.value) || 0
  const preferenceEnabled = areBitsSet(cookieValue, 1)

  const data = vueuseStorage(
    key,
    defaultValue,
    !isPreference || preferenceEnabled ? localStorage : sessionStorage
  )

  return data
}
