import { SKETCHFAB_MODELS_ENDPOINT } from '@config'
import { isArray } from '@helpers/utils.js'
import { createClient } from '@services'

const { get } = createClient()

export async function getSketchFabThumbsRequest(uuids) {
  if (uuids && isArray(uuids)) {
    const requests = uuids.map((uuid) => {
      return get(`${SKETCHFAB_MODELS_ENDPOINT}/${uuid}`, {
        external: true,
      })
    })
    const response = await Promise.allSettled(requests)
    return response
      .filter((r) => r.status === 'fulfilled')
      .map((r) => r.value.data)
  } else {
    throw new Error()
  }
}
