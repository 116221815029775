import { languageParam } from '@composables/useQueryParams.js'
import messages from '@/src/js/locales/messages.js'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  legacy: false,
  locale: languageParam || 'en',
  fallbackLocale: 'en',
  messages,
})

export default i18n
