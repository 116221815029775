import { cookieState, ALLOW_COOKIES } from '@composables/useStorage.js'
import { Style, ALLOWED_URLS, setStyle } from '@config/game.js'
import i18n from '@plugins/localisation.js'
import { isObj } from '@helpers/utils.js'

export default function () {
  window.addEventListener('message', (e) => {
    if (
      (ALLOWED_URLS.length && !ALLOWED_URLS.includes(e.origin)) ||
      !e.data ||
      !isObj(e.data)
    )
      return

    const { modioStyle, modioAllowCookies, modioLang } = e.data
    if (modioStyle) {
      Object.keys(Style.value).forEach((key) => setStyle(key, modioStyle[key]))
    }

    if (modioAllowCookies !== undefined) {
      const allow = ['1', 1, 'true', true].includes(modioAllowCookies)
      cookieState.value = allow ? ALLOW_COOKIES : 0
    }

    if (modioLang) {
      i18n.global.locale.value = modioLang
    }
  })
}
