import { genRanId, secsToMs } from '@helpers/utils.js'
import { TOAST_SECONDS } from '@config'
import { ref, computed } from 'vue'

const queue = ref([])
// {
//   title: 'toast title',
//   text: 'toast text',
//   isError: true,
//   hasClose: false,
// }
export default function () {
  const activeToasts = computed(() => queue.value)

  function addToast(
    {
      title,
      text,
      isSuccess,
      isInfo,
      isWarning,
      isError,
      hasClose,
      animate = true,
    } = {},
    name = genRanId()
  ) {
    const toast = {
      name,
      title,
      text,
      isSuccess,
      isInfo,
      isWarning,
      isError,
      hasClose,
      animate,
    }

    queue.value.push(toast)

    if (!hasClose) {
      setTimeout(() => {
        close(name)
      }, secsToMs(TOAST_SECONDS))
    }
  }

  function close(name) {
    queue.value = queue.value.filter((q) => q.name !== name)
  }

  function clearToast() {
    queue.value = []
  }

  return {
    activeToasts,
    clearToast,
    addToast,
    close,
  }
}
