import { ref } from 'vue'
const interruptedPaths = ref(new Set())

// cannot use useRoute as it causes dependency loop
export default function () {
  let startPath = null

  function startInterrupt(path) {
    startPath = path
  }

  function interrupted(path) {
    if (!path) {
      return
    }

    return interruptedPaths.value.delete(path)
  }

  function endInterrupt(path) {
    if (!path || !startPath || path !== startPath) {
      if (interruptedPaths.value.size > 10) {
        interruptedPaths.value.clear()
      }
      interruptedPaths.value.add(path)
    }
  }

  return { endInterrupt, startInterrupt, interrupted }
}
