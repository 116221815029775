<template>
  <div class="tw-w-full tw-flex tw-flex-col tw-items-center">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ViewContainer',
}
</script>
