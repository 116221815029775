import { computed, ref } from 'vue'

export default function () {
  const state = ref(false)

  const show = computed(() => {
    return state.value
  })

  function open() {
    state.value = true
  }

  function close() {
    state.value = false
  }

  function toggle() {
    state.value = !state.value
  }
  return {
    toggle,
    close,
    show,
    open,
  }
}
