<template>
  <div
    class="tw-flex tw-items-center tw-h-4.5 tw-label-gap"
    :class="labelWidth"
  >
    <div
      class="tw-h-2 tw-rounded-full"
      :class="[
        labelWidth,
        altBg ? 'tw-bg-theme' : 'game-tile-background-color',
      ]"
    ></div>
  </div>
</template>
<script>
import { computed, toRefs } from 'vue'
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    random: {
      type: Boolean,
      default: false,
    },
    altBg: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { random, small } = toRefs(props)
    const sizes = ['10', '14', '18']

    const labelWidth = computed(() => {
      if (small.value) {
        return 'tw-w-10'
      }
      return random.value ? twSize() : 'tw-w-14'
    })

    function twSize() {
      let obj = sizes[Math.floor(Math.random() * sizes.length)]
      if (obj === '10') {
        return 'tw-w-10'
      } else if (obj === '14') {
        return 'tw-w-14'
      } else {
        return 'tw-w-18'
      }
    }

    return {
      labelWidth,
    }
  },
}
</script>
