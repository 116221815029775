import { clone, isString } from '@helpers/utils.js'
import { ref, computed, readonly } from 'vue'

const state = ref({ store: {} })

export default function (key = null, data = {}) {
  if (key) {
    createStore(key, _cloneStore(data))
  }

  function getStore(key) {
    return computed(() => {
      if (state.value.store[key]) {
        return readonly(state.value.store[key])
      } else {
        return null
      }
    })
  }

  function generateId(key, ...ids) {
    return key.concat(`-${ids.join('-')}`)
  }

  function createStore(key, data) {
    const _data = _cloneStore(data)
    const updatedStore = {
      ...state.value.store,
      [key]: _data,
    }
    state.value.store = updatedStore
  }

  function updateStore(key, data = null) {
    createStore(key, data)
  }

  function _cloneStore(data) {
    if (isString(data)) {
      return clone(state.value.store[data])
    }

    return clone(data)
  }

  function addToStoreData(key, item) {
    const store = state.value.store[key]
    if (store?.data?.length) {
      const storeData = [...store.data]
      const index = storeData.findIndex((resource) => resource.id === item.id)

      index !== -1 ? storeData.splice(index, 1, item) : storeData.push(item)

      updateStore(key, { ...store, data: storeData })
    } else {
      createStore(key, {
        ...store,
        data: [item],
      })
    }
  }

  function getKeys() {
    return Object.keys(state.value.store)
  }

  return {
    addToStoreData,
    updateStore,
    createStore,
    generateId,
    getStore,
    getKeys,
  }
}
