import { cookieState, ALLOW_COOKIES } from '@composables/useStorage.js'

const params = new URLSearchParams(location.search)

export const gameIdParam = params.get('game_id')
export const apiKeyParam = params.get('api_key')
export const languageParam = params.get('lang')
export const cookiesParam = params.get('cookies')
export const tagsParam = params.get('tags-in')
export const featuredParam = params.get('featured')

export default function () {
  if (cookiesParam !== null) {
    cookieState.value = cookiesParam === 'allow' ? ALLOW_COOKIES : 0
  }
}
