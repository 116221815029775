import { authStore, headerStore } from '@stores'
import i18n from '@plugins/localisation.js'
import { PUBLIC_BASE_URL } from '@config'
import axios from 'axios'

export function create() {
  axios.defaults.baseURL = PUBLIC_BASE_URL
  axios.defaults.headers.get['Content-Type'] =
    'application/x-www-form-urlencoded'
  axios.defaults.headers.common['Content-Type'] =
    'application/x-www-form-urlencoded'
  axios.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded'
  axios.defaults.headers.delete['Content-Type'] =
    'application/x-www-form-urlencoded'
  axios.defaults.headers.common['Accept'] = 'application/json'

  return axios
}

function _setHeaders(config) {
  const { accessToken, getUserLanguage } = authStore()
  const { headers } = headerStore()

  if (accessToken.value) {
    if (config.anonymous !== true) {
      config.headers.Authorization = `Bearer ${accessToken.value}`
    }
  }

  config.headers['Accept-Language'] =
    i18n.global.locale.value || getUserLanguage()

  if (headers.value?.portal) {
    config.headers['X-Modio-Portal'] = headers.value.portal
  }
  if (headers.value?.platform) {
    config.headers['X-Modio-Platform'] = headers.value.platform
  }

  return config
}

axios.interceptors.request.use(function (config) {
  config = _setHeaders(config)
  return config
})
