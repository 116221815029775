import { requestAll } from '@helpers/requestAll.js'
import { queryString } from '@helpers/utils.js'
import { PUBLIC_BASE_URL } from '@config'
import { GAME_ID } from '@config/game.js'
import { createClient } from '@services'
import qs from 'qs'

const { get, post, del } = createClient()

/* API request to get user */
export async function getUserRequest() {
  const { data } = await get(`${PUBLIC_BASE_URL}/me`)
  return data
}

/* API request to update user subscriptions */
export function getMySubscriptionsRequest() {
  const query = { game_id: GAME_ID }
  return requestAll(`${PUBLIC_BASE_URL}/me/subscribed${queryString(query)}`)
}

/* API request to get user's ratings */
export function getAllUserRatings() {
  const query = { game_id: GAME_ID }
  return requestAll(`${PUBLIC_BASE_URL}/me/ratings${queryString(query)}`)
}

/* API request to subscribe to mod */
export function subscribeToModRequest({ gameId, modId } = {}) {
  return post(
    `${PUBLIC_BASE_URL}/games/${gameId}/mods/${modId}/subscribe`,
    qs.stringify({ noop: 'noop' })
  )
}

/* API request to unsubscribe to mod */
export async function unsubscribeToModRequest({ gameId, modId } = {}) {
  const { data } = await del(
    `${PUBLIC_BASE_URL}/games/${gameId}/mods/${modId}/subscribe`
  )
  return data
}

/* API request to update to mod rating */
export function updateModRatingRequest({ gameId, modId, rating } = {}) {
  return post(
    `${PUBLIC_BASE_URL}/games/${gameId}/mods/${modId}/ratings`,
    qs.stringify({ rating })
  )
}
