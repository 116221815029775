import { BREAKPOINTS as _BREAKPOINTS } from './breakpoints.js'
import { GAME_ID } from '@config/game.js'
/* eslint-disable */

// ENVIRONMENT
export const ENV = __ENV__
export const PROD_DEVTOOLS = __VUE_PROD_DEVTOOLS__

export const REPLICATION_DELAY_MS = __REPLICATION_DELAY_MS__
export const API_RESULT_LIMIT = 100
export const ORIGIN = window.location.origin

// ENDPOINTS
export const PUBLIC_BASE_URL = `https://g-${GAME_ID}.modapi.io/v1`
export const SKETCHFAB_MODELS_ENDPOINT = 'https://api.sketchfab.com/v3/models'
export const LOGOUT_ENDPOINT = `${PUBLIC_BASE_URL}/oauth/logout`

// PLACEHOLDER IMAGES
export const PREVIEW_IMAGE_KEY = 'thumb_1280x720'
export const CARD_IMAGE_KEY = 'thumb_320x180'

export const LEGAL_AGE = 18
export const SCROLL_TO_ID = 'container'
export const NEW_RESOURCE_ID = 'new'
export const IMAGE_CROP_SIZES = [
  '50x50',
  '64x64',
  '100x100',
  '128x128',
  '256x256',
  '320x180',
  '640x360',
  '1280x720',
  '1020x2000',
]

export const PAGINATION_DEFAULT_PAGE = 1
export const PAGINATION_DEFAULT_TOTAL = 0
export const PAGINATION_DEFAULT_LIMIT = 30

// RATINGS
export const RATINGS_LIKE = 1
export const RATINGS_DISLIKE = -1
export const RATINGS_UNDECIDED = 0

// TOAST
export const TOAST_SECONDS = 5

// ACTIVE ONLINE USER
export const ONLINE_USER_TIMEOUT_SECONDS = 600

// Use to redirect after icon animation completes
export const BUTTON_ANIMATION_DURATION = 2000
export const BUTTON_ICON_ANIMATION_DURATION = BUTTON_ANIMATION_DURATION - 500

// LOCALE
export const LOCALE_LANGUAGE = 'en-US'

export const BREAKPOINTS = _BREAKPOINTS
export const PHP_UNIXTIME_LEN = 10

export const IS_IOS_TOUCH_DEVICE =
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
