export function addBlankTargets(text) {
  return ('' + text).replace(
    /<a\s+href=/gi,
    '<a target="_blank" rel="noreferrer noopener" href='
  )
}

export function stripLinks(text) {
  return ('' + text).replace(/(<a [^>]*>)(.*?)(<\/a>)/gi, '<u>$2</u>')
}
