import Serializer, { extractCardImage } from './Serializer.js'
import { clone, isObj } from '@helpers/utils.js'

const transformations = [transformTags, extractCardImage(''), _UGCSingle]

const gameSerializer = new Serializer({
  transformations,
})

function _UGCSingle(game) {
  game.ugc_name = game.ugc_name || 'Mods'
  game.ugc_name_single = game.ugc_name.replace(/s$/, '')
  return game
}

function transformTags(game) {
  const gameCopy = clone(game)
  if (gameCopy.tag_options) {
    gameCopy.tag_options = _setTagIds(gameCopy.tag_options)
  }

  return gameCopy
}

function _setTagIds(tags) {
  tags.forEach((tag, index) => {
    tag.id = `T-${index}`
    tag.selected = []
    // Handle cases where tags are saved as an object in API
    tag.tags = isObj(tag.tags) ? Object.values(tag.tags) : tag.tags
  })
  return tags
}

export default gameSerializer
