import { LS_SUBSCRIPTIONS } from '@config/localstorage.js'
import modSerializer from '@serializers/modSerializer.js'
import useStorage from '@composables/useStorage.js'
import { isArray } from '@helpers/utils.js'
import { computed } from 'vue'

const subscriptionState = useStorage(LS_SUBSCRIPTIONS, null, false)

export default function () {
  const subscriptions = computed(() => {
    try {
      const json = JSON.parse(subscriptionState.value)
      if (isArray(json)) return json
    } catch (error) {
      console.error(error)
    }
    return []
  })

  function clearSubscriptions() {
    subscriptionState.value = JSON.stringify([])
  }

  function setMySubscriptions(subscriptions) {
    const { data } = modSerializer.transform({ data: subscriptions })
    subscriptionState.value = JSON.stringify(data)
    return data
  }

  // use game and mod id
  function addSubscription(modId, modData) {
    if (isArray(subscriptions.value)) {
      const subscription = subscriptions.value.find(
        (sub) => sub && sub.id === parseInt(modId)
      )

      if (!subscription) {
        setMySubscriptions([...subscriptions.value, modData])
      }
    } else {
      setMySubscriptions([modData])
    }
  }

  // use game and mod id
  function removeSubscription(modId) {
    const updated = subscriptions.value.filter(
      (subscription) => subscription && subscription.id !== parseInt(modId)
    )

    setMySubscriptions(updated)
  }

  function hasSubscriptionForUser(userNameId) {
    return computed(() =>
      subscriptions.value?.some(
        (mod) => mod.submitted_by.name_id === userNameId
      )
    )
  }

  return {
    hasSubscriptionForUser,
    setMySubscriptions,
    clearSubscriptions,
    removeSubscription,
    addSubscription,
    subscriptions,
  }
}
