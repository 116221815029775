import { computed, ref } from 'vue'

const state = ref([])

export default function () {
  const boundaries = computed(() => state.value)

  const mainTop = computed(
    () => state.value && state.value.main && state.value.main.top
  )
  const mainLeft = computed(
    () => state.value && state.value.main && state.value.main.left
  )
  const mainRight = computed(
    () => state.value && state.value.main && state.value.main.right - 1
  )
  const mainBottom = computed(() => state.value && state.value.screenHeight)

  function setBoundaryVars(key, value) {
    state.value[key] = value
  }

  return {
    setBoundaryVars,
    boundaries,
    mainBottom,
    mainRight,
    mainLeft,
    mainTop,
  }
}
