import { LS_RATINGS } from '@config/localstorage.js'
import useStorage from '@composables/useStorage.js'
import { isArray } from '@helpers/utils.js'
import i18n from '@plugins/localisation.js'
import modStore from '@stores/modStore.js'
import { computed } from 'vue'
import {
  RATINGS_TEXT_OPTIONS,
  RATINGS_UNDECIDED,
  RATINGS_DISLIKE,
  RATINGS_MANY,
  RATINGS_LIKE,
  RATINGS_FEW,
} from '@config/options.js'

const ratingsState = useStorage(LS_RATINGS, null, false)

export default function () {
  const { getModByNameId, addMod } = modStore()
  const ratings = computed(() => JSON.parse(ratingsState.value))

  function clearRatings() {
    ratingsState.value = JSON.stringify([])
  }

  function setUserRatings(ratings) {
    const noDateRating = ratings.map((rating) => {
      const newRating = { ...rating }
      delete newRating.date_added
      return newRating
    })
    ratingsState.value = JSON.stringify(noDateRating)
  }

  // Use ids instead of name_ids to match /me/ratings endpoint
  function updateUserRating(gameId, modId, update) {
    let updatedRatings = []
    if (isArray(ratings.value)) {
      updatedRatings = ratings.value.filter((rating) => {
        return !(
          rating.game_id === parseInt(gameId) &&
          rating.mod_id === parseInt(modId)
        )
      })
    }

    updatedRatings.push({
      game_id: gameId,
      mod_id: modId,
      rating: parseInt(update),
    })

    setUserRatings(updatedRatings)
  }

  function updateModRating({ gameNameId, modNameId, update, current }) {
    const t = i18n.global.t
    const mod = getModByNameId(gameNameId, modNameId)
    if (!mod.value) {
      throw new Error('cannot find mod')
    }
    const modCopy = { ...mod.value }
    const stats = { ...modCopy.stats }

    if (current === RATINGS_LIKE && update === RATINGS_DISLIKE) {
      stats.ratings_positive--
      stats.ratings_negative++
    }

    if (current === RATINGS_LIKE && update === RATINGS_UNDECIDED) {
      stats.ratings_positive--
    }

    if (current === RATINGS_DISLIKE && update === RATINGS_LIKE) {
      stats.ratings_positive++
      stats.ratings_negative--
    }

    if (current === RATINGS_DISLIKE && update === RATINGS_UNDECIDED) {
      stats.ratings_negative--
    }

    if (current === RATINGS_UNDECIDED) {
      if (update === RATINGS_LIKE) {
        stats.ratings_positive++
      } else {
        stats.ratings_negative++
      }
    }

    const ratingsTotal = stats.ratings_positive + stats.ratings_negative
    const percentage = Math.round((stats.ratings_positive / ratingsTotal) * 100)

    if (!isNaN(percentage)) {
      stats.ratings_percentage_positive = percentage
    } else {
      stats.ratings_percentage_positive = 0
    }

    stats.ratings_display_text = t(RATINGS_TEXT_OPTIONS.UNRATED)
    if (ratingsTotal > 0) {
      if (
        stats.ratings_percentage_positive >= 95 &&
        ratingsTotal > RATINGS_MANY
      ) {
        stats.ratings_display_text = t(
          RATINGS_TEXT_OPTIONS.OVERWHELMINGLY_POSITIVE
        )
      } else if (
        stats.ratings_percentage_positive >= 80 &&
        ratingsTotal > RATINGS_FEW
      ) {
        stats.ratings_display_text = t(RATINGS_TEXT_OPTIONS.VERY_POSITIVE)
      } else if (stats.ratings_percentage_positive >= 80) {
        stats.ratings_display_text = t(RATINGS_TEXT_OPTIONS.POSITIVE)
      } else if (stats.ratings_percentage_positive >= 70) {
        stats.ratings_display_text = t(RATINGS_TEXT_OPTIONS.MOSTLY_POSITIVE)
      } else if (stats.ratings_percentage_positive >= 40) {
        stats.ratings_display_text = t(RATINGS_TEXT_OPTIONS.MIXED)
      } else if (ratingsTotal < RATINGS_FEW) {
        stats.ratings_display_text = t(RATINGS_TEXT_OPTIONS.NEGATIVE)
      } else if (stats.ratings_percentage_positive >= 20) {
        stats.ratings_display_text = t(RATINGS_TEXT_OPTIONS.MOSTLY_NEGATIVE)
      } else if (ratingsTotal > RATINGS_MANY) {
        stats.ratings_display_text = t(
          RATINGS_TEXT_OPTIONS.OVERWHELMINGLY_NEGATIVE
        )
      } else {
        stats.ratings_display_text = t(RATINGS_TEXT_OPTIONS.VERY_NEGATIVE)
      }
    }

    modCopy.stats = stats
    addMod(gameNameId, modCopy)
  }

  return {
    updateUserRating,
    updateModRating,
    setUserRatings,
    clearRatings,
    ratings,
  }
}
