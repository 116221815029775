import { computed, ref } from 'vue'

const globalModalState = ref(null)

export const globalModal = computed(() => globalModalState.value?.modal)
export const globalModalData = computed(() => globalModalState.value?.data)

export function setGlobalModal({ modal, data } = {}) {
  if (!modal) return
  globalModalState.value = { modal, data }
}

export function clearGlobalModal() {
  globalModalState.value = null
}

export default function useModal() {
  const state = ref(false)

  function showModal() {
    state.value = true
    _addBodyClass()
  }

  function hideModal() {
    state.value = false
    _removeBodyClass()
  }

  const isModalOpen = computed(() => {
    return state.value
  })

  const triggerModal = () => {
    state.value === true ? hideModal() : showModal()
  }

  function _addBodyClass() {
    document.body.classList.add('tw-overflow-hidden')
  }

  function _removeBodyClass() {
    document.body.classList.remove('tw-overflow-hidden')
  }

  return {
    triggerModal,
    isModalOpen,
    showModal,
    hideModal,
  }
}
